<template>
    <v-app>
        <div class="frame-popup">
            <header>
                <div class="is-left">
                    <h1>지점정보</h1>
                    <v-btn class="jh-btn is-icon" @click="btnRefresh"><i class="jh-icon-refresh is-white"></i></v-btn>

                </div>
                <div class="is-right">
                    <v-btn class="jh-btn is-icon" title="닫기" @click="close"><i class="jh-icon-close-lg is-white"></i></v-btn>
                </div>
            </header>
            <main><T100101 ref="brchInfo"></T100101></main>
        </div>        
    </v-app>
</template>

<script>
import { mixin } from "@/mixin/mixin.js";
import T100101 from "./T100101.vue";

export default {
    name: "MENU_PT100101", //name은 'MENU_' + 파일명 조합
    mixins: [mixin],
    components: {
        T100101
    },
    data() {
        return {
        }
    },
    created() { 
    },
    mounted() {
        // 토큰 동기화를 위해 메인창에 window 객체에 팝업 windows 객체 저장
        // window 팝업에서 palette api를 호출할 경우 필수
        if (opener) {
            if (!opener.popups) opener.popups = [];
                opener.popups.push(self);
        }
    },
    computed: {
    },
    methods: {
        close(){
            self.close();
        },
        btnRefresh() {
            this.$refs.brchInfo.selectBrInfo();
        }
    },
};
</script>

<style></style>  